import Vue from "vue";
import VueRouter from "vue-router";
import MainContent from "@/components/MainContent";
import store from "@/store";
import LoadingComponent from "@/components/LoadingComponent.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale/:slug",
    component: MainContent
  },
  {
    path: "/loading",
    component: LoadingComponent
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  const defaultSlug = store.getters["app/getDefaultSlug"]; // ваш геттер для получения дефолтного слага
  const currentLocale = store.getters["app/getLocale"]; // текущая локаль из стора
  const localeList = store.getters["app/getLocalesList"]; // список всех возможных локалей

  // Проверяем, идёт ли загрузка данных
  if (store.getters["app/isLoadingData"]) {
    // Если пользователь не на странице загрузки, сохраняем намерение перехода
    if (to.path !== "/loading") {
      // Сохраняем целевой маршрут для использования после загрузки данных
      store.commit("app/SET_INTENDED_ROUTE", to);

      // Переход на страницу загрузки
      next("/loading");
    } else {
      // Уже на странице загрузки, ничего не делаем
      next();
    }

    // Устанавливаем watcher на окончание загрузки данных
    const unwatch = store.watch(
      (state) => state.app.isLoadingData,
      (isLoading) => {
        if (!isLoading) {
          // Данные загружены, отменяем watcher
          unwatch();

          // Получаем сохранённый маршрут или используем дефолтный
          const intendedRoute = store.getters["app/getIntendedRoute"] || {
            path: `/${currentLocale}/${defaultSlug}`
          };

          // Переходим на сохранённый или дефолтный маршрут
          next(intendedRoute);
        }
      }
    );
  } else {
    const pathSegments = to.path.split("/").filter(Boolean); // разделение пути и удаление пустых сегментов

    let localeSegment = pathSegments[0]; // предполагаемый сегмент локали
    let slugSegment = pathSegments[1]; // предполагаемый сегмент слага

    // Если нет ни локали, ни слага - редирект на URL с дефолтной локалью и слагом
    if (pathSegments.length === 0) {
      next(`/${currentLocale}/${defaultSlug}`);
      return;
    }

    // Если есть локаль, но нет слага - редирект на URL с текущей локалью и дефолтным слагом
    if (pathSegments.length === 1 && localeList.includes(localeSegment)) {
      next(`/${localeSegment}/${defaultSlug}`);
      return;
    }

    // Если локаль из URL не соответствует текущей локали из стора, нужно проверить в списке доступных локалей
    // и редиректить на URL с правильной локалью и текущим или дефолтным слагом
    if (localeSegment !== currentLocale) {
      let correctLocale = currentLocale;
      if (localeList.includes(localeSegment)) {
        store.dispatch("app/setLocale", localeSegment);
        correctLocale = localeSegment;
      }
      const correctSlug = slugSegment || defaultSlug;
      next(`/${correctLocale}/${correctSlug}`);
      return;
    }

    // Если все в порядке, просто переходим к маршруту
    next();
  }
});

export default router;
