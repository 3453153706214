<template>
  <div class="header">
    <div class="header__wrapper">
      <div
        class="header__burger"
        :class="{ open: isSidebarOpen }"
        @click="toggleSidebar"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="header__logo-wrap">
        <a href="https://coinfuze.com/" class="header__logo-wrap">
          <img
            v-if="theme === 'dark'"
            class="header__logo header__logo--desktop"
            src="../assets/images/logo/logo.svg"
            alt="logo"
          />
          <img
            v-else
            class="header__logo header__logo--desktop"
            src="../assets/images/logo/coinfuze_black.svg"
            alt="logo"
          />
          <img
            class="header__logo header__logo--mob"
            src="../assets/images/logo/logo-mob.svg"
            alt="logo"
          />
        </a>
      </div>
      <div class="header__faq">Docs</div>
    </div>

    <div class="header__wrapper">
      <div class="header__lang-wrap">
        <div class="header__lang" @click="toggleLangMenu">
          {{ currentLocale }}
        </div>
        <ul class="header__lang-menu" v-if="langMenuOpened">
          <li
            class="header__lang-item"
            :class="{ 'header__lang-item--active': locale === currentLocale }"
            v-for="(locale, idx) in locales"
            @click="setLocale(locale)"
            :key="idx + locale"
          >
            {{ locale.toLocaleUpperCase() }}
          </li>
        </ul>
      </div>
      <div class="header__icon" @click="setTheme">
        <span
          v-if="theme === 'dark'"
          class="header__icon-moon icon-moon-o"
        ></span
        ><span v-else class="header__icon-sun icon-sun1"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vClickOutside from "v-click-outside";

export default {
  name: "AppHeader",
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      darkTheme: false,
      langMenuOpened: false
    };
  },
  mounted() {
    this.setDefaultTheme();
  },
  computed: {
    ...mapGetters({
      currentLocale: "app/getLocale",
      locales: "app/getLocalesList",
      theme: "app/getTheme"
    })
  },
  methods: {
    setDefaultTheme() {
      if (this.theme === "dark") {
        document.body.classList.add("dark-theme");
      } else {
        document.body.classList.remove("dark-theme");
      }
    },
    closeSidebar() {
      this.$emit("close-sidebar");
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    toggleLangMenu() {
      this.langMenuOpened = !this.langMenuOpened;
    },
    closeLangMenu() {
      this.langMenuOpened = false;
    },
    setLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$store
          .dispatch("app/setLocale", locale)
          .then(() => {
            document.documentElement.setAttribute("lang", locale);
            const currentPath = this.$route.path;

            const newPath = `/${locale}${currentPath.replace(
              /^\/[a-zA-Z]{2}\//,
              "/"
            )}`;

            this.$router.push(newPath);
          })
          .finally(() => this.closeLangMenu());
      }
    },
    setTheme() {
      this.$store.commit(
        "app/SET_THEME",
        this.theme === "dark" ? "light" : "dark"
      );
      if (this.theme === "dark") {
        document.body.classList.add("dark-theme");
      } else {
        document.body.classList.remove("dark-theme");
      }
    }
  }
};
</script>

<style lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  color: $main-text;
  background-color: $main-text;
  padding: 0 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  border-bottom: 1px solid #f1f1f1;
  @media (max-width: 1800px) {
    padding: 0 30px;
    font-size: 0.875rem;
  }
  @media (max-width: 1450px) {
    font-size: 0.75rem;
  }
  @media (max-width: 1400px) {
    padding: 0 20px;
  }
  @media (max-width: 700px) {
    padding: 20px 10px;
  }
  @media (max-width: 375px) {
    padding: 20px 10px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__logo {
    width: 110px;
    &--desktop {
      @media (max-width: 600px) {
        display: none;
      }
    }
    &--mob {
      @media (min-width: 601px) {
        display: none;
      }
      width: 24px;
    }
  }
  &__items {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 425px) {
      gap: 20px;
    }
  }
  &__burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
    @media (min-width: 991px) {
      display: none;
    }
    @media (max-width: 1000px) {
      display: flex;
    }
    & span {
      width: 25px;
      height: 2px;
      background-color: $dark-color;
      display: block;
      transition: all 0.2s ease-in-out;
    }
    &.open span:nth-child(1) {
      transform: rotate(45deg) translate(8.5px, 8.5px);
    }
    &.open span:nth-child(2) {
      transform: rotate(-45deg) translate(4px, -4px);
    }
    &.open span:nth-child(3) {
      display: none;
    }
  }
  &__burger-wrap {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 600px) {
      gap: 20px;
    }
  }
  &__blur {
    opacity: 0.9;
    background-color: #ddd;
    min-height: 100vh;
  }
  &__icon {
    width: 24px;
  }
  &__icon-moon {
    color: $main-text;
    font-size: 24px;
    cursor: pointer;
    &:hover {
      color: $green;
    }
  }
  &__icon-sun {
    color: $dark-color;
    font-size: 24px;
    cursor: pointer;
    &:hover {
      color: $light-green;
    }
  }

  &__lang-wrap {
    position: relative;
  }
  &__lang {
    text-transform: uppercase;
    font-size: 16px;
    color: $dark-color;
    font-weight: 500;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      color: $light-green;
    }
  }
  &__lang-menu {
    background-color: $main-text;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    list-style: none;
    margin: 0;
    padding: 15px;
    position: absolute;
    top: 130%;
    left: -12px;
    z-index: 1;
    border-radius: 5px;
    transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
    &.active {
      display: flex;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in-out, visibility 0s linear 0s;
    }
    & li {
      line-height: 1.5;
    }
  }
  &__lang-item {
    color: $dark-color;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: $light-green;
    }
  }
  &__lang-item--active {
    color: $light-green;
  }
  &__faq {
    color: $light-green;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;
  }
  &__logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  &__dropdown-content {
    position: absolute;
    top: 130%;
    left: calc(50% - 80px);
    background-color: $main-text;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index: 1;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
    &.visible {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in-out, visibility 0s linear;
    }
  }
}

.hidden {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.relative {
  position: relative;
}
</style>
