<template>
  <div id="app">
    <app-header
      :isSidebarOpen="isSidebarOpen"
      @toggle-sidebar="toggleSideBar"
      @close-sidebar="closeSidebar"
    />
    <side-bar-menu
      v-if="!loading"
      :isSidebarOpen="isSidebarOpen"
      @close-sidebar="closeSidebar"
    />
    <p v-if="loading">loading....</p>
    <div v-else style="position: relative">
      <router-view />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import SideBarMenu from "./components/SideBarMenu.vue";

export default {
  name: "App",
  components: { AppHeader, SideBarMenu },
  data() {
    return {
      loading: false,
      isSidebarOpen: false
    };
  },
  created() {
    this.getAppData();
  },
  mounted() {
    const script = document.createElement("script");
    script.innerHTML = `(function(w, d, s, h, id) {
w.roistatProjectId = id; w.roistatHost = h;
var p = d.location.protocol == "https:" ? "https://" : "http://";
var u = /^.roistat_visit=[^;]+(.)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
})(window, document, 'script', 'cloud.roistat.com', 'f5154a47715fbb5b35fb704cd376a28d');`;
    document.body.appendChild(script);
  },
  methods: {
    getAppData() {
      this.loading = true;
      this.$store
        .dispatch("app/getDocsData")
        .finally(() => (this.loading = false));
    },
    toggleSideBar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    }
  }
};
</script>

<style lang="scss"></style>
